<template>
  <div></div>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ConfigClear',
  created() {
    console.log('created');
    this.clear();
  },
  methods: {
    ...mapMutations({
      clearConfiguration: 'config/clearConfiguration',
    }),
    clear() {
      this.clearConfiguration();
      this.$router.push({ name: 'config' });
    },
  },
}
</script>
